import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputComponent from 'components/shared/InputComponent';
import Select from 'components/shared/Select';
import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import TagsInput from 'react-tagsinput';
import { toast } from 'react-toastify';
import { createWord } from 'services/Teacher/Vocabulary.service';

const AddStudySetWord = ({ show, handleClose ,addWordToVocabulary}) => {
    const [title, setTitle] = useState("");
    const [sentence, setSentence] = useState("");
    const [classWord, setClassWord] = useState("noun");
    const [difficulty, setDifficulty] = useState("intermediate");
    const [meanings, setMeanings] = useState([]);

    const handleTagsChange = (newTags) => {
        setMeanings(newTags);
    };




    const handleSubmit = async (e) => {
        e.preventDefault();

        const studySetData = {
            title,
            sentence,
            difficulty,
            meaning: meanings,
            language: "English",
            class: classWord,
        };
        const toastId = toast.loading('Creating Word');
        try {
            const response = await createWord(studySetData);
            const newWordId = response.data.result._id;

            toast.update(toastId, {
                render: 'Word successfully created.',
                type: 'success',
                isLoading: false,
                autoClose: 3000,
            });

            // Assuming you want to add to a specific vocabulary item
            addWordToVocabulary(newWordId); // Example ID, replace with the actual vocabulary ID

            handleClose();
        } catch (error) {
            console.error('Error adding study set:', error);

            toast.update(toastId, {
                render: 'Error adding study set.',
                type: 'error',
                isLoading: false,
                autoClose: 3000,
            });
        }
    };

    const levels = [
        { value: 'beginner', label: 'beginner' },
        { value: 'elementary', label: 'elementary' },
        { value: 'intermediate', label: 'intermediate' },
        { value: 'advanced', label: 'advanced' },
        { value: 'expert', label: 'expert' },
    ];
    const classOptions = [
        { value: 'noun', label: 'noun' },
        { value: 'verb', label: 'verb' },
        { value: 'adjective', label: 'adjective' },
    ];

    return (
        <Modal show={show} onHide={handleClose} centered size={"lg"}>
            <Modal.Header className='border-0' closeButton />
            <Modal.Body>
                <div className="text-center">
                    <FontAwesomeIcon className='p-3' icon={faAdd} style={{ fontSize: "40px", color: "#e15354", border: "3px solid #e15354", borderRadius: "60px", width: "40px", height: "40px" }} />
                    <h3 className='mt-1'>Add Word</h3>
                </div>

                <Form onSubmit={handleSubmit}>
                    <InputComponent
                        label={"Title"}
                        placeholder={"Study set title"}
                        value={title}
                        setValue={setTitle}
                        type={"text"}
                    />

                    <InputComponent
                        label={"Sentence"}
                        placeholder={"Enter sentence"}
                        value={sentence}
                        setValue={setSentence}
                        type={"text"}
                    />
                    <div>
                        <Form.Label>Enter meanings</Form.Label>
                        <TagsInput value={meanings} onChange={handleTagsChange} />
                    </div>

                    <div>
                        <Form.Label className='mb-0 mt-1'>Difficulty Level</Form.Label>
                        <Select
                            options={levels}
                            value={difficulty}
                            onChange={setDifficulty}
                        />

                    </div>
                    <div>
                        <Form.Label className=' mb-0'>Word class</Form.Label>
                        <Select
                            options={classOptions}
                            value={classWord}
                            onChange={setClassWord}
                        />

                    </div>


                    <Modal.Footer className='border-0 justify-content-between pt-0'>
                        <Button
                            variant="secondary"
                            className='mt-1 px-4 button-style py-2 border-0'
                            style={{ background: "linear-gradient(270deg, #272727 0%, #4B4B4B 100%)" }}
                            onClick={handleClose}
                        >
                            Back
                        </Button>
                        <Button
                            variant="primary"
                            className='mt-1 px-4 button-style py-2 border-0'
                            type="submit"
                        >
                            Add Word
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddStudySetWord;
