import React from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { deleteClass } from 'services/Teacher/Class.service';

const Groups = ({ classes, isLoading, fetchClasses }) => {
    const handleDelete = async (classId) => {

        const confirmDelete = window.confirm("Are you sure you want to delete this class?");
        if (!confirmDelete) return;

        const toastId = toast.loading('Deleting class...');

        try {
            await deleteClass(classId);
            toast.update(toastId, { render: 'Class deleted successfully!', type: 'success', isLoading: false, autoClose: 3000 });
            fetchClasses();
        } catch (error) {
            console.error('Error deleting class:', error);
            toast.update(toastId, { render: 'Error deleting class', type: 'error', isLoading: false, autoClose: 3000 });
        }
    };

    return (
        <>
            <div className='my-3' style={{ maxHeight: '300px', overflowY: 'auto' }}>
                {isLoading ? (
                    Array.from({ length: 4 }).map((_, index) => (
                        <div
                            key={index}
                            className='py-0 custom-item text-wrap d-flex justify-content-between border-bottom mb-2'
                        >
                            <div className='d-flex gap-3 my-3'>
                                <div>
                                    <Skeleton circle={true} height={40} width={40} />
                                </div>
                                <div>
                                    <div>
                                        <Skeleton width={100} height={20} />
                                    </div>
                                    <Skeleton width={150} height={15} />
                                </div>
                            </div>
                            <div className='mt-1'>
                                <div>
                                    <Skeleton width={70} height={15} />
                                </div>
                                <div
                                    className='d-flex justify-content-center align-items-center ms-auto'
                                    style={{
                                        height: '20px',
                                        width: '20px',
                                        borderRadius: '60px'
                                    }}
                                >
                                    <Skeleton circle={true} height={20} width={20} />
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    classes?.map((classItem) => (
                        <Link key={classItem?._id} className='text-decoration-none'>
                            <div className='py-0 custom-item text-wrap d-flex justify-content-between border-bottom mb-2'>
                                <div className='d-flex gap-3 my-3'>
                                    <div
                                        style={{
                                            width: '44px',
                                            height: '44px',
                                            borderRadius: '60px',
                                            background: '#000000'
                                        }}
                                    ></div>
                                    <div>
                                        <div>
                                            <h6
                                                className='m-0'
                                                style={{ color: '#303972', fontWeight: '700' }}
                                            >
                                                {classItem?.title}
                                            </h6>
                                        </div>
                                        <small className='text-muted text-decoration-none'>
                                            {classItem?.subject.title || ''}
                                        </small>
                                    </div>
                                </div>
                                <div>
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        className='text-danger me-3'
                                        onClick={() => handleDelete(classItem._id)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            </div>
                        </Link>
                    ))
                )}
            </div>
        </>
    );
};

export default Groups;
