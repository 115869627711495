import { faAdd, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteSchoolModal from 'components/modals/DeleteSchoolModal';
import PageHeading from 'components/shared/PageHeading';
import React, { useEffect, useState } from 'react';
import { Button, Card, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteStudySet, getAllVocabulary } from 'services/Teacher/Vocabulary.service';

const Vocabulary = () => {
    const navigate = useNavigate();
    const [vocabulary, setVocabulary] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const fetchVocabulary = async () => {
        setLoading(true);
        try {
            const response = await getAllVocabulary();
            setVocabulary(response.data.results.studySets || []);
        } catch (error) {
            console.log("Error fetching libraries:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchVocabulary();
    }, []);

    const handleShowModal = (id) => {
        setSelectedId(id);
        setShowDeleteModal(true);
    };

    const handleCloseModal = () => {
        setShowDeleteModal(false);
        setSelectedId(null);
    };

    const handleStudySetDelete = async () => {
        if (!selectedId) return;

        const toastId = toast.loading("Deleting...");
        try {
            await deleteStudySet(selectedId);
            setShowDeleteModal(false);
            toast.update(toastId, {
                render: "Deleted successfully!",
                type: "success",
                isLoading: false,
                autoClose: 3000,
            });
            fetchVocabulary();
        } catch (error) {
            console.error("Error deleting the study set:", error);
            toast.update(toastId, {
                render: "Failed to delete the study set.",
                type: "error",
                isLoading: false,
                autoClose: 3000,
            });
        }
    };

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <PageHeading heading={"Vocabulary"} />
                </div>
                <div>
                    <Button
                        className='button-style border-0 py-2'
                        onClick={() => navigate("/vocabulary/create-vocabulary")}
                    >
                        <FontAwesomeIcon className='pe-2' icon={faAdd} />Create Study set
                    </Button>
                </div>
            </div>
            <Card className='border-0 mt-4 p-3' style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", background: "#FBF3F3", borderRadius: "20px" }}>
                <Card.Body className='p-0'>
                    <Table responsive="sm" style={{ background: "transparent", borderCollapse: "separate", borderSpacing: "0 10px" }}>
                        <thead >
                            <tr>
                                <th style={{ background: "transparent", fontWeight: "700", paddingLeft: "14px",borderColor:"transparent" }}>Subject</th>
                                <th style={{ background: "transparent", fontWeight: "700", paddingLeft: "14px",borderColor:"transparent" }}>Description</th>
                                <th style={{ background: "transparent", fontWeight: "700", paddingLeft: "14px",borderColor:"transparent" }}>Size</th>
                                <th style={{ background: "transparent", fontWeight: "700", paddingLeft: "14px",borderColor:"transparent" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {vocabulary?.map((vocab, index) => (
                                <tr key={index}>
                                    <td onClick={() => navigate(`${vocab._id}`)} style={{ fontSize: "14px", fontWeight: 400, color: "#858585", padding: "15px", verticalAlign: "middle", borderTopLeftRadius: "30px", borderBottomLeftRadius: "30px", cursor: "pointer" }}>
                                        {vocab?.title}
                                    </td>
                                    <td style={{ fontSize: "14px", fontWeight: 400, color: "#858585", padding: "15px", verticalAlign: "middle" }}>
                                        {vocab?.description}
                                    </td>
                                    <td style={{ fontSize: "14px", fontWeight: 400, color: "#858585", padding: "15px", verticalAlign: "middle" }}>
                                        {vocab?.maxSize}
                                    </td>
                                    <td style={{ fontSize: "14px", fontWeight: 400, color: "#858585", padding: "15px", verticalAlign: "middle", borderTopRightRadius: "30px", borderBottomRightRadius: "30px" }}>
                                        <FontAwesomeIcon
                                            onClick={() => handleShowModal(vocab._id)}
                                            icon={faTrash}
                                            className='text-danger'
                                            style={{ cursor: "pointer" }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            <DeleteSchoolModal
                show={showDeleteModal}
                handleClose={handleCloseModal}
                handleConfirmDelete={handleStudySetDelete}
            />
        </>
    );
}

export default Vocabulary;
