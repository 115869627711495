import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import ManualVocabulary from './ManualVocabulary';
import AiGeneratedVocabulary from './AiGeneratedVocabulary';
import PageHeading from 'components/shared/PageHeading';

const CreateVocabulary = () => {
    const [key, setKey] = useState(1);
    return (
        <>
            <PageHeading heading={"Create Vocabulary"} />
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="my-3"
            >
                <Tab eventKey="1" title="Add Manually">
                    <ManualVocabulary />
                </Tab>
                <Tab eventKey="2" title="Create study set (AI Generated)">
                    <AiGeneratedVocabulary />
                </Tab>

            </Tabs>
        </>
    )
}

export default CreateVocabulary