import { GetApiData } from "utils/http-client";


export const getAllClasses = async () => {
    return await GetApiData(`/class`, 'GET', null, true);
};
export const CreateClass = async (data) => {
    return await GetApiData(`/class`, 'POST', data, true);
};
export const deleteClass = async (id) => {
    return await GetApiData(`/class/${id}`, 'DELETE', null, true);
};


export const deleteExerciseQuestions = async (id) => {
    return await GetApiData(`/class/${id}`, 'DELETE', null, true);
};