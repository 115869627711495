import DenyExerciseModal from 'components/modals/DenyExerciseModal';
import BreadCrumbs from 'components/shared/BreadCrumbs';
import PageHeading from 'components/shared/PageHeading';
import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Button, Placeholder } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getLibraryExerciseDetail, UpdateExerciseStatus } from 'services/Admin/UserManagement/Exercise.service';

const ViewSubmissions = () => {
    const [exerciseInfo, setExerciseInfo] = useState(null);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [writtenResponses, setWrittenResponses] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const params = useParams();
    const id = params.id;

    useEffect(() => {
        const fetchExerciseDetails = async () => {
            try {
                setLoading(true);
                const response = await getLibraryExerciseDetail(id);
                setExerciseInfo(response?.data?.result?.exercise);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching Exercise details:", error);
            }
        };

        fetchExerciseDetails();
    }, [id]);

    const handleOptionChange = (questionId, selectedOption) => {
        setSelectedAnswers({
            ...selectedAnswers,
            [questionId]: selectedOption,
        });
    };

    const handleTextAreaChange = (questionId, value) => {
        setWrittenResponses({
            ...writtenResponses,
            [questionId]: value,
        });
    };

    const handleInputChange = (questionId, value) => {
        setWrittenResponses({
            ...writtenResponses,
            [questionId]: value,
        });
    };

    const handleStatusChange = async (id, newStatus) => {
        const toastId = toast.loading("Updating status...");
        try {
            await UpdateExerciseStatus(id, newStatus);

            toast.update(toastId, {
                render: "Status updated successfully!",
                type: "success",
                isLoading: false,
                autoClose: 3000,
                closeOnClick: true,
            });
            navigate("/exercise-management");
        } catch (error) {
            toast.update(toastId, {
                render: error.message || "Error updating status",
                type: "error",
                isLoading: false,
                autoClose: 3000,
                closeOnClick: true,
            });
            console.error('Error updating status:', error);
        }
    };

    const breadcrumbItems = [
        { label: 'Exercise Management', href: '/exercise-management' },
        { label: exerciseInfo?.title, active: true }
    ];

    const isCorrectAnswer = (question, answer) => {
        return answer === question.correctAnswer;
    };

    return (
        <>
            <BreadCrumbs items={breadcrumbItems} />
            <div>
                {exerciseInfo ? (
                    <PageHeading heading={exerciseInfo?.exerciseType} text={`Title: ${exerciseInfo?.title}`} />
                ) : (
                    <Placeholder className="mb-3" as="div" animation="glow">
                        <Placeholder xs={3} className="mb-2" />
                        <div>
                            <Placeholder xs={6} />
                        </div>
                    </Placeholder>
                )}
            </div>
            <div style={{ height: "65vh", border: "1px solid #A7A7A7", borderRadius: "30px", padding: "30px", boxShadow: "rgba(234, 79, 80, 0.1) 0px 4px 12px" }}>
                <Row className='h-100'>
                    <Col xs={6} style={{ borderRight: "2px solid #8A8A8A", height: "60vh", overflow: "auto" }}>
                        {exerciseInfo ? (
                            <>
                                <h3 className='text-capitalize'>{`${exerciseInfo?.creationMethod} Generated Test:`}</h3>
                                <p>{exerciseInfo?.passage}</p>
                            </>
                        ) : (
                            <Placeholder as="div" animation="glow">
                                <Placeholder xs={6} className="mb-2" />
                                <Placeholder xs={8} />
                                <Placeholder xs={4} />
                                <Placeholder xs={7} />
                            </Placeholder>
                        )}
                    </Col>
                    <Col xs={6} style={{ height: "60vh", overflow: "auto" }}>
                        <h3 className='text-capitalize'>{`Questions`}</h3>
                        {exerciseInfo ? (
                            exerciseInfo?.questions?.map((question, index) => {
                                return (
                                    <div key={question._id} style={{ marginBottom: '20px' }}>
                                        <h5 style={{ fontWeight: "700" }} className='text-capitalize'>{question.type.replace("-", " ")}</h5>

                                        <h6>{`Q${question.order + 1}: ${question.questionText}`}</h6>

                                        {question.type === "multiple-choice" && (
                                            <Form>
                                                {question.options.map((option, i) => (
                                                    <Form.Check
                                                        type="radio"
                                                        label={option}
                                                        name={`question-${question._id}`}
                                                        key={i}
                                                        value={option}
                                                        checked={selectedAnswers[question._id] === option}
                                                        onChange={() => handleOptionChange(question._id, option)}
                                                        disabled
                                                        style={{
                                                            backgroundColor: isCorrectAnswer(question, option) ? 'green' : 'transparent',
                                                            color: isCorrectAnswer(question, option) ? 'white' : 'black',
                                                            padding: '5px',
                                                            borderRadius: '5px',
                                                            display: 'block',
                                                            marginBottom: '5px'
                                                        }}
                                                    />
                                                ))}
                                            </Form>
                                        )}

                                        {question.type === "true-false" && (
                                            <Form>
                                                <Form.Check
                                                    type="radio"
                                                    label="True"
                                                    name={`question-${question._id}`}
                                                    value="True"
                                                    checked={selectedAnswers[question._id] === "True"}
                                                    onChange={() => handleOptionChange(question._id, "True")}
                                                    disabled
                                                    style={{
                                                        backgroundColor: isCorrectAnswer(question, "True") ? 'green' : 'transparent',
                                                        color: isCorrectAnswer(question, "True") ? 'white' : 'black',
                                                        padding: '5px',
                                                        borderRadius: '5px',
                                                        display: 'block',
                                                        marginBottom: '5px'
                                                    }}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label="False"
                                                    name={`question-${question._id}`}
                                                    value="False"
                                                    checked={selectedAnswers[question._id] === "False"}
                                                    onChange={() => handleOptionChange(question._id, "False")}
                                                    disabled
                                                    style={{
                                                        backgroundColor: isCorrectAnswer(question, "False") ? 'green' : 'transparent',
                                                        color: isCorrectAnswer(question, "False") ? 'white' : 'black',
                                                        padding: '5px',
                                                        borderRadius: '5px',
                                                        display: 'block',
                                                        marginBottom: '5px'
                                                    }}
                                                />
                                            </Form>
                                        )}

                                        {(question.type === "written-answer" || question.type === "essay") && (
                                            <Form.Group>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={4}
                                                    value={writtenResponses[question._id] || ''}
                                                    onChange={(e) => handleTextAreaChange(question._id, e.target.value)}
                                                    placeholder="Write your answer here..."
                                                    disabled
                                                />
                                            </Form.Group>
                                        )}

                                        {question.type === "fill-in-the-blank" && (
                                            <Form.Group>
                                                <Form.Control
                                                    type="text"
                                                    value={writtenResponses[question._id] || ''}
                                                    onChange={(e) => handleInputChange(question._id, e.target.value)}
                                                    placeholder="Fill in the blank..."
                                                    disabled
                                                />
                                            </Form.Group>
                                        )}
                                    </div>
                                );
                            })
                        ) : (
                            <Placeholder as="div" animation="glow">
                                <Placeholder xs={8} className="mb-2" />
                                <Placeholder xs={12} />
                                <Placeholder xs={10} />
                            </Placeholder>
                        )}
                    </Col>
                </Row>
            </div>
            <div className='mt-2 d-flex justify-content-between'>
                <div>
                    <Button variant='primary' type='submit' className='mt-1 px-4 button-style py-2 border-0' onClick={() => handleStatusChange(id, 'rejected')} style={{ background: " linear-gradient(270deg, #272727 0%, #4B4B4B 100%)" }}>
                        Deny
                    </Button>
                </div>
                <div className='d-flex gap-2'>
                    <Button variant='primary' type='submit' className='mt-1 px-4 button-style py-2 border-0' onClick={() => handleStatusChange(id, 'accepted')} >
                        Accept
                    </Button>
                </div>
            </div>
            <DenyExerciseModal show={show} handleClose={handleClose} id={id} />
        </>
    );
};

export default ViewSubmissions;
