import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Assignments from './Assignments'
import AttemptExercise from './AttemptExercise'
import ViewSubmission from './ViewSubmission'
import MarkSubmission from 'views/Teacher/Assignments/MarkSubmission'

const AssignmentRoutes = () => {
    return (
        <>
            <Routes>
                <Route path='/' element={<Assignments />} />
                <Route path='/attempt/:_id' element={<AttemptExercise/>} />
                <Route path='/submission/:_id' element={<ViewSubmission/>} />
                <Route path='/submission/marked/:_id' element={<MarkSubmission/>} />
            </Routes>
        </>
    )
}

export default AssignmentRoutes