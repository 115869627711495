import { GetApiData } from "utils/http-client";


export const getAllTeachersAndStudents = async (id, role) => {
    return await GetApiData(`/school/users/${id}?role=${role}`, 'GET', null, true);
};

export const UpdateStudentStatus = async (id, newStatus) => {
    return await GetApiData(`/user/status/${id}`, 'PUT', { status: newStatus }, true);
};
