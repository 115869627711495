import TextArea from 'components/shared/TextArea';
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const ReasonModal = ({ show, onClose, onSave }) => {
    const [reason, setReason] = useState('');

    const handleSave = () => {
        onSave(reason);
        setReason('');
    };

    return (
        <Modal show={show} onHide={onClose} centered size={"lg"}>
            <Modal.Header closeButton className='border-0'>
                <Modal.Title>Provide a Reason</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="reasonInput">
                    <Form.Label>Please provide a reason for putting this exercise under review:</Form.Label>
                       <TextArea
                        // heading="Comments (further review needed)"
                        rows={3}
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}

                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className='border-0 justify-content-between'>
                <Button variant="secondary" className='mt-1 px-4 button-style py-2 border-0' style={{ background: " linear-gradient(270deg, #272727 0%, #4B4B4B 100%)" }} onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="primary" className='mt-1 px-4 button-style py-2 border-0' onClick={handleSave} disabled={!reason.trim()}>
                    Send
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ReasonModal;
