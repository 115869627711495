import InputComponent from 'components/shared/InputComponent';
import Select from 'components/shared/Select';
import React, { useState } from 'react';
import { Button, Card, Form, } from 'react-bootstrap';

const AiGeneratedVocabulary = () => {
  const [theme, setTheme] = useState("");
  const [title, setTitle] = useState("");
  const [wordType, setWordType] = useState("");
  const [language, setLanguage] = useState('');
  const options = [
    { value: 'english', label: 'English' },
    { value: 'swedish', label: 'Swedish' },
  ];
  const wordtype = [
    { value: 'Noun', label: 'Noun' },
    { value: 'Verb', label: 'Verb' },
    { value: 'Adjective', label: 'Adjective' },
  ];
  return (
    <>
      <Card className='border-0 shadow p-3'>
        <Form>
          <InputComponent
            label={"Theme"}
            placeholder={"Write Theme"}
            value={theme}
            setValue={setTheme}
            type={"text"}
          />
          <InputComponent
            label={"Title"}
            placeholder={"Enter Title"}
            value={title}
            setValue={setTitle}
            type={"text"}
          />
          <div className="d-flex gap-4">
            <div>
              <Select
                heading={"Word Class"}
                value={wordType}
                onChange={setWordType}
                options={wordtype}
                ariaLabel="Select Language"
                required
              />
            </div>
            <div>

              <Form.Label>Difficulty Level</Form.Label>
              <div className="mt-3 d-flex gap-4">
                <div>
                  <Form.Check
                    type="checkbox"
                    label="Beginner"
                    id="checkbox-important"
                  />
                </div>
                <div>
                  <Form.Check
                    type="checkbox"
                    label="Intermediate"
                    id="checkbox-reviewed"
                  />
                </div>
                <div>
                  <Form.Check
                    type="checkbox"
                    label="Difficult"
                    id="checkbox-learned"
                  />
                </div>
              </div>

            </div>
          </div>


          <div className="d-flex gap-3 align-items-center">
            <div>
              Size of Study Set
            </div>
            <div>
              <InputComponent
                placeholder={"Study Set Size"}
                value={title}
                setValue={setTitle}
                type={"text"}
              />
            </div>
            <div>
              number of words generated
            </div>
            <div>
              <InputComponent
                placeholder={"Words Generated"}
                value={title}
                setValue={setTitle}
                type={"text"}
              />
            </div>

          </div>



          <div className='d-flex gap-4 mt-3 align-items-center'>
            <div>
              <Form.Check
                type="checkbox"
                label="Add Translation in (Optional)"
                id="checkbox-important"
              />
            </div>
            <div>
              <Select
                value={language}
                onChange={setLanguage}
                options={options}
                ariaLabel="Select Language"
                required
              />
            </div>
          </div>
        </Form>

        <div className="d-flex justify-content-between mt-3">
          <div>
            <Button className='mt-1 px-4 button-style py-2 border-0'
              style={{ background: "linear-gradient(270deg, #272727 0%, #4B4B4B 100%)" }}>
              Back
            </Button>
          </div>
          <div>
            <Button className='button-style border-0 py-2 px-4'>
              Create
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
}

export default AiGeneratedVocabulary;
