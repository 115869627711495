import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ClassesAndGroups from './ClassesAndGroups'

const TeacherClassesAndGroupsRoutes = () => {
    return (
        <>
            <Routes>
                <Route
                    path='/'
                    element={<ClassesAndGroups />}
                />
               

            </Routes>
        </>
    )
}

export default TeacherClassesAndGroupsRoutes