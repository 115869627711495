import React, { useEffect, useState } from 'react';
import { Button, Card, Dropdown, Form, Image, InputGroup, Table } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChangeExerciseLibraryStatus } from 'services/Admin/UserManagement/Exercise.service';
import { toast } from 'react-toastify';
import nodataimage from "../../../assets/nodataimage.svg";
import { faEye, faFile, faFilter, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageHeading from 'components/shared/PageHeading';
import CustomPagination from 'components/shared/Pagination';
import ReasonModal from 'components/modals/ReasonModal';
import DeleteAssignmentModal from 'components/modals/DeleteTeacherAssignmentModal';
import { deleteAssignments, getAllAssignment } from 'services/Teacher/Assignments.service';
import moment from 'moment';
import AssignedClassesAndStudent from 'components/modals/AssignedClassesAndStudent';
import dropdown from "../../../assets/dropdown.svg";

const TeacherAssignments = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const initialPage = parseInt(searchParams.get('page'), 10) || 1;
    const initialLimit = parseInt(searchParams.get('limit'), 10) || 3;
    const initialSearchQuery = searchParams.get('search') || '';

    const [exercises, setExercises] = useState([]);
    const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
    const [search, setSearch] = useState(initialSearchQuery);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(initialPage);
    const [totalPages, setTotalPages] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [showEye, setShowEye] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [pendingStatusChange, setPendingStatusChange] = useState(null);

    const limit = initialLimit;

    const AllExercises = async (page = 1, limit = 2, search = '') => {
        setLoading(true);
        try {
            const response = await getAllAssignment(page, limit, search);
            if (response.data && response.data.results) {
                setExercises(response.data.results.assignments || []);
                setTotalPages(response.data.results.totalPages || 1);
                console.log("response", response.data.results);
            } else {
                setExercises([]);
                setTotalPages(1);
            }
        } catch (error) {
            console.error("Error fetching exercises:", error);
            setExercises([]);
            setTotalPages(1);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        AllExercises(currentPage, limit, searchQuery);
    }, [currentPage, limit, searchQuery]);

    useEffect(() => {
        const params = new URLSearchParams({ page: currentPage, limit, search: searchQuery });
        navigate({ search: `?${params.toString()}` });
    }, [currentPage, limit, searchQuery, navigate]);

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        setSearchQuery(search);
        setCurrentPage(1);
    };

    const updateStatus = async (id, newStatus, reason = '') => {
        const toastId = toast.loading("Updating status...");
        try {
            await ChangeExerciseLibraryStatus(id, { status: newStatus, message: reason });
            AllExercises(currentPage, limit, searchQuery);
            toast.update(toastId, {
                render: "Status updated successfully!",
                type: "success",
                isLoading: false,
                autoClose: 3000,
                closeOnClick: true,
            });
        } catch (error) {
            toast.update(toastId, {
                render: "Error updating status",
                type: "error",
                isLoading: false,
                autoClose: 3000,
                closeOnClick: true,
            });
            console.error('Error updating status:', error);
        }
    };

    const handleSaveReason = (reason) => {
        if (pendingStatusChange) {
            updateStatus(pendingStatusChange.id, pendingStatusChange.newStatus, reason);
        }
        setShowModal(false);
        setPendingStatusChange(null);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleShowDeleteModal = (id) => {
        setPendingStatusChange({ id, newStatus: 'deleted' });
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleDeleteExercise = async (id) => {
        const toastId = toast.loading("Deleting exercise...");
        try {
            await deleteAssignments(id);
            AllExercises(currentPage, limit, searchQuery);
            toast.update(toastId, {
                render: "Exercise deleted successfully!",
                type: "success",
                isLoading: false,
                autoClose: 3000,
                closeOnClick: true,
            });
        } catch (error) {
            toast.update(toastId, {
                render: "Error deleting exercise",
                type: "error",
                isLoading: false,
                autoClose: 3000,
                closeOnClick: true,
            });
            console.error('Error deleting exercise:', error);
        } finally {
            setShowDeleteModal(false);
            setPendingStatusChange(null);
        }
    };

    const handleShowEyeModal = () => {
        setShowEye(true);
    };

    const handleCloseEyeModal = () => {
        setShowEye(false);
    };

    return (
        <>
            <ReasonModal
                show={showModal}
                onClose={() => setShowModal(false)}
                onSave={handleSaveReason}
            />
            <div className="d-block d-lg-flex justify-content-between">
                <div>
                    <PageHeading heading={"Assignments"} />
                </div>
                <div className="d-block d-lg-flex gap-3 justify-content-between">
                    <div>
                        <Button
                            className='px-4 py-2 w-100 w-lg-auto my-lg-0 my-2'
                            style={{ color: "#858585", borderRadius: "30px", background: "white", border: "2px solid #858585", fontWeight: 600 }}
                        >
                            Sort By
                            <FontAwesomeIcon icon={faFilter} className="ms-2" />
                        </Button>
                    </div>
                    <div>
                        <Form.Group className='mb-3 position-relative' controlId='formBasicPassword'>
                            <InputGroup className='mb-3'>
                                <Form.Control
                                    type={'text'}
                                    placeholder='Search Here...'
                                    className='px-3 py-2 border-right-0'
                                    value={search}
                                    onChange={handleSearchChange}
                                    style={{ borderTopLeftRadius: "30px", borderBottomLeftRadius: "30px", borderColor: "#ea4f50", borderRight: "0" }}
                                />
                                <InputGroup.Text
                                    id='basic-addon2'
                                    className='bg-white'
                                    style={{ cursor: 'pointer', borderTopRightRadius: "30px", borderBottomRightRadius: "30px", borderColor: "#ea4f50", borderLeft: "0" }}
                                    onClick={handleSearchSubmit}
                                >
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                    </div>
                </div>
            </div>
            <div className='mt-4'>
                <Table responsive="sm" style={{ overflow: "auto" }}>
                    <thead>
                        <tr>
                            <th style={{ color: "#303972" }}>Assignment Name</th>
                            <th style={{ color: "#303972" }}>Max time</th>
                            <th style={{ color: "#303972" }}>Deadline</th>
                            <th style={{ color: "#303972" }}>Assigned To</th>
                            <th style={{ color: "#303972" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            [...Array(limit)].map((_, index) => (
                                <tr key={index}>
                                    <td className='py-3'>
                                        <div style={{ backgroundColor: "#e0e0e0", height: "20px", width: "80%", borderRadius: "4px" }}></div>
                                    </td>
                                    <td className='py-3'>
                                        <div style={{ backgroundColor: "#e0e0e0", height: "20px", width: "40%", borderRadius: "4px" }}></div>
                                    </td>
                                    <td className='py-3'>
                                        <div style={{ backgroundColor: "#e0e0e0", height: "20px", width: "40%", borderRadius: "4px" }}></div>
                                    </td>
                                    <td className='py-3'>
                                        <div style={{ backgroundColor: "#e0e0e0", height: "20px", width: "40%", borderRadius: "4px" }}></div>
                                    </td>
                                    <td className='py-3'>
                                        <div style={{ backgroundColor: "#e0e0e0", height: "20px", width: "40%", borderRadius: "4px" }}></div>
                                    </td>
                                </tr>
                            ))
                        ) : exercises.length === 0 ? (
                            <tr>
                                <td colSpan="5" className='text-center'>
                                    <Image src={nodataimage} alt="No Data" className='img-fluid' />
                                    <p>No assignments found</p>
                                </td>
                            </tr>
                        ) : (
                            exercises.map((exercise, index) => (
                                <tr key={exercise._id}>
                                    <td className='py-3'>
                                        <span style={{ color: "#303972", fontWeight: 700 }}>{exercise.title}</span>

                                    </td>
                                    <td className='py-3' style={{ color: "#A098AE" }}>{exercise?.maxTime} minutes</td>
                                    <td className='py-3' style={{ color: "#A098AE" }}>{moment(exercise?.endDate).format("DD MMM YYYY")}</td>

                                    <td className='py-3' style={{ color: "#A098AE" }}>

                                        <FontAwesomeIcon onClick={handleShowEyeModal} icon={faEye} className='text-muted' style={{ cursor: "pointer" }} />

                                    </td>
                                    <td className='py-3' style={{ color: "#A098AE" }}>

                                        <Dropdown>
                                            <Dropdown.Toggle className='more bg-transparent text-black border-0 e-caret-hide rounded-0' id="dropdown-basic">
                                                <Card.Img style={{ width: "24px", height: "24px" }} variant="top" src={dropdown} />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className='border-0' style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
                                                <Dropdown.Item
                                                    style={{ color: "black" }}
                                                    onClick={() => navigate("submissions", { state: { exerciseId: exercise._id } })}
                                                >
                                                    <FontAwesomeIcon icon={faFile} size='20' className="me-2" />
                                                    All Submissions
                                                </Dropdown.Item>

                                                <Dropdown.Item onClick={handleShowDeleteModal} style={{ color: "black" }}>
                                                    <FontAwesomeIcon icon={faEye} className="me-2" />
                                                    Show Exercise
                                                </Dropdown.Item>
                                                <Dropdown.Item   onClick={() => handleShowDeleteModal(exercise._id)} style={{ color: "red" }}>
                                                    <FontAwesomeIcon icon={faTrash} className="me-2" />
                                                    Delete
                                                </Dropdown.Item>


                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </td>


                                </tr>
                            ))
                        )}
                    </tbody>
                </Table>
                <CustomPagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />
            </div>
            <DeleteAssignmentModal
                show={showDeleteModal}
                handleClose={handleCloseDeleteModal}
                handleConfirmDelete={() => handleDeleteExercise(pendingStatusChange.id)}
            />
        </>
    );
};

export default TeacherAssignments;
