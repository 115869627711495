import React from 'react';
import { Form } from 'react-bootstrap';

const Select = ({ heading, value, onChange, options, ariaLabel, required, isInvalid }) => (
    <Form.Group className='my-2' controlId={heading}>
        {heading && (
            <>
                <Form.Label>{heading}</Form.Label>
            </>
        )}

        <Form.Control
            as="select"
            value={value || ""}
            onChange={(e) => onChange(e.target.value)}
            aria-label={ariaLabel}
            required={required}
            isInvalid={isInvalid}
            style={{ borderRadius: "30px" }}
        >
            <option value="" disabled hidden>Select an option</option>
            
            {options?.map(option => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </Form.Control>

        <Form.Control.Feedback type="invalid">
            This field is required.
        </Form.Control.Feedback>
    </Form.Group>
);

export default Select;
