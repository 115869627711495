import React from 'react';
import { Pagination } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const CustomPagination = ({ currentPage, totalPages, onPageChange }) => {

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };

    return (
        <div className="d-flex align-items-center justify-content-end">
            <button
                className='me-2 border-0 bg-transparent d-flex align-items-center'
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
            >
                <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
            </button>

            <Pagination className="d-flex gap-2 mb-0">
                {Array?.from({ length: totalPages }, (_, i) => i + 1)?.map(page => (
                    <Pagination.Item
                        key={page}
                        active={page === currentPage}
                        onClick={() => onPageChange(page)}
                    >
                        {page}
                    </Pagination.Item>
                ))}
            </Pagination>

            <button
                className='ms-2 border-0 bg-transparent d-flex align-items-center'
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
            >
                <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
            </button>
        </div>
    );
};

export default CustomPagination;
