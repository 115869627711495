import { GetApiData } from "utils/http-client";

export const Assignments = async (data) => {
    return await GetApiData(`/assignment`, 'POST', data, true);
};
export const deleteAssignments = async (id) => {
    return await GetApiData(`/assignment/${id}`, 'DELETE', null, true);
};
export const getAllAssignment = async () => {
    return await GetApiData(`/assignment`, 'GET', null, true);
};
export const getAllSubmissions = async (id) => {
    return await GetApiData(`/submission/${id}?status=submitted`, 'GET', null, true);
};
