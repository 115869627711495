import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

function BreadCrumbs({ items }) {
    const navigate = useNavigate();

    return (
        <div className='d-flex '>
            <Button
                onClick={() => navigate(-1)}
                className='border-0 bg-transparent d-flex'
            >
                <FontAwesomeIcon style={{color:"#df4c4e",fontSize:"24px"}} icon={faChevronLeft} />
            </Button>
            <Breadcrumb className='d-flex align-items-center'>
                {items.map((item, index) =>
                    item.active ? (
                        <Breadcrumb.Item  key={index} active>
                            {item.label}
                        </Breadcrumb.Item>
                    ) : (
                        <Breadcrumb.Item key={index}>
                            <Link to={item.href}>
                                {item.label}
                            </Link>
                        </Breadcrumb.Item>
                    )
                )}
            </Breadcrumb>
        </div>
    );
}

export default BreadCrumbs;
