import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const AssignedClassesAndStudent = ({ show, handleClose, handleConfirmDelete }) => {

    return (
        <Modal show={show} onHide={handleClose} centered size={"md"}>
            <Modal.Header className='border-0' closeButton>
            </Modal.Header>
            <Modal.Body className='text-center'>


            </Modal.Body>
            <Modal.Footer className='border-0 justify-content-between pt-0'>
                <Button
                    variant="secondary"
                    type='submit'
                    className='mt-1 px-4 button-style py-2 border-0'
                    style={{ background: "linear-gradient(270deg, #272727 0%, #4B4B4B 100%)" }}
                    onClick={handleClose}
                >
                    Back
                </Button>
                <Button
                    variant="primary"
                    type='submit'
                    className='mt-1 px-4 button-style py-2 border-0'
                    onClick={handleConfirmDelete}
                >
                    Yes Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AssignedClassesAndStudent;
