import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextArea from "components/shared/TextArea";
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";

const RevisionModal = ({ show, handleClose, handleConfirm, loading }) => {
  return (
    <Modal show={show} onHide={handleClose} centered size={"md"}>
      <Modal.Header className="border-0" closeButton></Modal.Header>
      <Modal.Body className="text-start">
        <div className="text-center">
          <FontAwesomeIcon
            className="p-3 mb-3"
            icon={faRefresh}
            style={{
              fontSize: "40px",
              color: "green",
              border: "3px solid green ",
              borderRadius: "60px",
              width: "40px",
              height: "40px",
            }}
          />

          <h5 className="mt-1">
            Are you sure you want to request a revision for this submission?
          </h5>
        </div>

        <TextArea row={5} heading={"Kindly Provide Feedback (Optional)"} />
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-between pt-0">
        <Button
          variant="secondary"
          type="submit"
          className="mt-1 px-4 button-style py-2 border-0"
          style={{
            background: "linear-gradient(270deg, #272727 0%, #4B4B4B 100%)",
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          disabled={loading}
          className="mt-1 px-4 button-style py-2 border-0"
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RevisionModal;
