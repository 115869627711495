import TextArea from 'components/shared/TextArea';
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';

const DenyExerciseModal = ({ show, handleClose }) => {
    const [comments, setComments] = useState("")

    return (
        <>


            <Modal show={show} onHide={handleClose} centered size={"lg"}>
                <Modal.Header className='border-0' closeButton>
                </Modal.Header>
                <Modal.Body>
                    <TextArea
                        heading="Comments (further review needed)"
                        rows={5}
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                    />

                </Modal.Body>
                <Modal.Footer className='border-0 justify-content-between'>
                    <Button variant="secondary" type='submit' className='mt-1 px-4 button-style py-2 border-0' style={{ background: " linear-gradient(270deg, #272727 0%, #4B4B4B 100%)" }} onClick={handleClose}>
                        Deny
                    </Button>
                    <Button variant="primary" type='submit' className='mt-1 px-4 button-style py-2 border-0' onClick={handleClose}>
                        Send
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DenyExerciseModal