import InputComponent from 'components/shared/InputComponent';
import React, { useState } from 'react';
import { Button, Card, Col, Form } from 'react-bootstrap';
import { createStudySet } from 'services/Teacher/Vocabulary.service';
import { toast } from 'react-toastify';

const ManualVocabulary = () => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [rangeValue, setRangeValue] = useState(2);
    const handleSubmit = async (event) => {
        event.preventDefault();
        const payload = {
            title,
            description,
            maxSize: rangeValue,
        };
        const toastId = toast.loading('Adding Study set');
        try {
            await createStudySet(payload);

            toast.update(toastId, {
                render: 'Study set successfully created.',
                type: 'success',
                isLoading: false,
                autoClose: 3000,
            });
        } catch (error) {
            console.log("error", error);
            toast.update(toastId, {
                render: `Error creating Study set`,
                type: 'error',
                isLoading: false,
                autoClose: 5000,
            });
        }
    };

    return (
        <>
            <Card className='border-0 shadow p-3'>
                <Form>
                    <InputComponent
                        label={"Title"}
                        placeholder={"Study set title"}
                        value={title}
                        setValue={setTitle}
                        type={"text"}
                    />
                    <InputComponent
                        label={"Description"}
                        placeholder={"A short description"}
                        value={description}
                        setValue={setDescription}
                        type={"text"}
                    />
                    <Col xs={3}>
                        <Form.Group controlId="formRange">
                            <Form.Label>Size : {rangeValue}</Form.Label>
                            <Form.Range
                                min={2}
                                max={20}
                                value={rangeValue}
                                onChange={(e) => setRangeValue(e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                </Form>

                <div className="d-flex justify-content-between mt-3">
                    <div>
                        <Button className='mt-1 px-4 button-style py-2 border-0'
                            style={{ background: "linear-gradient(270deg, #272727 0%, #4B4B4B 100%)" }}>
                            Back
                        </Button>
                    </div>
                    <div>
                        <Button className='button-style border-0 py-2 px-4' onClick={handleSubmit}>
                            Create
                        </Button>
                    </div>
                </div>
            </Card>

        </>
    );
}

export default ManualVocabulary;
