import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Vocabulary from './Vocabulary'
import CreateVocabulary from './CreateVocabulary'
import VocabularyDetail from './VocabularyDetail'

const VocabularyRoutesTeacher = () => {
    return (
        <>
            <Routes>
                <Route
                    path='/'
                    element={<Vocabulary />}
                />
                <Route
                    path='/create-vocabulary'
                    element={<CreateVocabulary />}
                />
                <Route
                    path='/:id'
                    element={<VocabularyDetail />}
                />

            </Routes>
        </>
    )
}

export default VocabularyRoutesTeacher