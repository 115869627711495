import { GetApiData } from "utils/http-client";

export const getAllVocabulary = async () => {
    return await GetApiData(`/vocabulary/studyset`, 'GET', null, true);
};
export const getVocabularyById = async (id) => {
    return await GetApiData(`/vocabulary/studyset/${id}`, 'GET', null, true);
};
export const updateVocabularyId = async (id,data) => {
    return await GetApiData(`/vocabulary/studyset/${id}`, 'PUT', data, true);
};
export const createStudySet = async (data) => {
    return await GetApiData(`/vocabulary/studyset`, 'POST', data, true);
};
export const deleteStudySet = async (id) => {
    return await GetApiData(`/vocabulary/studyset/${id}`, 'DELETE', null, true);
};
export const createWord = async (data) => {
    return await GetApiData(`/vocabulary/word`, 'POST', data, true);
};