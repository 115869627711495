import { GetApiData } from "utils/http-client";

export const createSubmission = async (data,status) => {
    return await GetApiData(`/submission?status=${status||""}`, 'POST', data, true);
};

export const getSubmission = async (id) => {
    return await GetApiData(`/submission/single/${id}`, 'GET',null, true);
};

export const markSubmission = async (data) => {
    return await GetApiData(`/submission/mark`, 'PUT',data, true);
};

export const sendToRevision = async (data) => {
    return await GetApiData(`/submission/revise`, 'POST',data, true);
};

export const submitAnswer = async (data) => {
    return await GetApiData(`/submission/submit`, 'POST',data, true);
};

export const forceSubmitAssignment = async (id) => {
    return await GetApiData(`/submission/force/${id}`, 'POST',null, true);
};