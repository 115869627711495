import { faArrowCircleLeft, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeading from "components/shared/PageHeading";
import React, { useEffect, useState } from "react";
import { Col, Row, Form, Button, Placeholder, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { forceSubmitAssignment, getSubmission, submitAnswer } from "services/Student/Submission.services";

const AttemptExercise = () => {
  const [exerciseInfo, setExerciseInfo] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [writtenResponses, setWrittenResponses] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [submissionId, setSubmissionId] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [showTimeUpModal, setShowTimeUpModal] = useState(false);
  const [isPassageVisible, setIsPassageVisible] = useState(true); 

  const navigate = useNavigate();
  const params = useParams();
  const id = params._id;

  useEffect(() => {
    const fetchExerciseDetails = async () => {
      try {
        setLoading(true);
        const response = await getSubmission(id);
        const { exercise, answers, _id, endTime } = response?.data?.result;

        const endTimeMs = new Date(endTime).getTime();
        const now = new Date().getTime();
        const timeDiff = endTimeMs - now;

        if (timeDiff > 0) {
          setTimeRemaining(timeDiff);
        } else {
          setShowTimeUpModal(true);
        }

        const prefilledAnswers = {};
        const prefilledWrittenResponses = {};
        answers.forEach((ans) => {
          prefilledAnswers[ans.question] = ans.answer;
        });

        setSelectedAnswers(prefilledAnswers);
        setWrittenResponses(prefilledWrittenResponses);
        setExerciseInfo(exercise);
        setSubmissionId(_id);

        const answeredQuestionIds = answers.map((a) => a.question);
        const firstUnansweredIndex = exercise.questions.findIndex(
          (q) => !answeredQuestionIds.includes(q._id)
        );
        setCurrentQuestionIndex(
          firstUnansweredIndex !== -1 ? firstUnansweredIndex : 0
        );

        setLoading(false);
      } catch (error) {
        console.error("Error fetching Exercise details:", error);
      }
    };

    fetchExerciseDetails();
  }, [id]);

  useEffect(() => {
    if (timeRemaining > 0) {
      const intervalId = setInterval(() => {
        setTimeRemaining((prev) => {
          if (prev <= 1000) {
            clearInterval(intervalId);
            forceSubmitAssignment(submissionId)
              .then(() => {
                toast.success("Time's up! Assignment has been submitted.");
                setShowTimeUpModal(true);
              })
              .catch((error) => {
                toast.error("Error submitting assignment after timeout.");
                console.error(error);
              });
            return 0;
          }
          return prev - 1000;
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timeRemaining]);

  const handleOptionChange = (questionId, selectedOption) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionId]: selectedOption,
    });
  };

  const handleTextAreaChange = (questionId, value) => {
    setWrittenResponses({
      ...writtenResponses,
      [questionId]: value,
    });
  };

  const handleSubmitAnswer = async () => {
    const currentQuestion = exerciseInfo?.questions[currentQuestionIndex];
    const answer =
      selectedAnswers[currentQuestion._id] || writtenResponses[currentQuestion._id];

    if (!answer) {
      toast.error("Please provide an answer before submitting.");
      return;
    }

    const payload = {
      submissionId,
      questionId: currentQuestion._id,
      answer,
    };

    try {
      await submitAnswer(payload);
      toast.success("Answer submitted successfully!");
      if (currentQuestionIndex < exerciseInfo?.questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        toast.success("All questions answered!");
        navigate("/assignments");
      }
    } catch (error) {
      toast.error("Error submitting answer, please try again.");
      console.error("Error submitting answer:", error);
    }
  };

  const formatTime = (ms) => {
    const totalSeconds = Math.floor(ms / 1000);
    const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
    const seconds = String(totalSeconds % 60).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  const currentQuestion = exerciseInfo?.questions[currentQuestionIndex];

  return (
    <>
      <div>
        {exerciseInfo ? (
          <div className="d-flex justify-content-between">
            <div>
              <PageHeading
                heading={exerciseInfo?.exerciseType}
                text={`Title: ${exerciseInfo?.title}`}
              />
            </div>

            {timeRemaining !== null && (
              <div className="timer mt-2 me-2" style={{
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "36px",
              }}>
                Time remaining:<span className="text-danger"> {formatTime(timeRemaining)}</span>
              </div>
            )}
          </div>

        ) : (
          <Placeholder className="mb-3" as="div" animation="glow">
            <Placeholder xs={3} className="mb-2" />
            <div>
              <Placeholder xs={6} />
            </div>
          </Placeholder>
        )}

      </div>


      <div
        style={{
          height: "65vh",
          border: "1px solid #A7A7A7",
          borderRadius: "30px",
          padding: "30px",
          boxShadow: "rgba(234, 79, 80, 0.1) 0px 4px 12px",
        }}
      >
        <Row className="h-100">
          <Col
            xs={isPassageVisible ? 6 : 0}
            className={`passage-container ${isPassageVisible ? "expanded" : "collapsed"}`}
            style={{
              borderRight: isPassageVisible ? "2px solid #8A8A8A" : "none",
              height: "52vh",
              display: isPassageVisible ? "block" : "none",
            }}
          >
            {exerciseInfo ? (
              <>
                <div>
                  <h3 className="text-capitalize">Comprehension Passage</h3>
                </div>

                <div
                  style={{
                    height: "100%",
                    overflow: "auto",
                  }}
                >
                  <p>{exerciseInfo?.passage}</p>
                </div>
              </>
            ) : (
              <Placeholder as="div" animation="glow">
                <Placeholder xs={6} className="mb-2" />
                <Placeholder xs={8} />
                <Placeholder xs={4} />
                <Placeholder xs={7} />
              </Placeholder>
            )}
          </Col>

          <Col xs={isPassageVisible ? 6 : 12} style={{ height: "60vh", overflow: "auto" }}>
            {currentQuestion ? (
              <div style={{ marginBottom: "20px" }}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    <div>
                      <div>
                        <Button
                          className="button-style border-0"
                          onClick={() => setIsPassageVisible((prev) => !prev)}
                        >
                          {!isPassageVisible ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faArrowCircleLeft} />}
                        </Button>
                      </div>
                      <div>
                        <h3 className="text-capitalize">
                          {`Question ${currentQuestionIndex + 1} of ${exerciseInfo?.questions.length
                            }`}
                        </h3>
                      </div>
                    </div>
                  </div>


                  <h5>{currentQuestion?.score} {currentQuestion?.score <= 1 ? `Point` : `Points`} </h5>
                </div>

                <h5 style={{ fontWeight: "700" }} className="text-capitalize">
                  {currentQuestion.type.replace("-", " ")}
                </h5>

                <h6>{`Question: ${currentQuestion.questionText}`}</h6>

                {currentQuestion.type === "multiple-choice" && (
                  <Form>
                    {currentQuestion.options.map((option, i) => (
                      <Form.Check
                        type="radio"
                        label={option}
                        name={`question-${currentQuestion._id}`}
                        key={i}
                        value={option}
                        checked={selectedAnswers[currentQuestion._id] === option}
                        onChange={() =>
                          handleOptionChange(currentQuestion._id, option)
                        }
                      />
                    ))}
                  </Form>
                )}

                {(currentQuestion.type === "written-answer" ||
                  currentQuestion.type === "essay") && (
                    <Form.Control
                      as="textarea"
                      rows={5}
                      value={writtenResponses[currentQuestion._id] || ""}
                      onChange={(e) =>
                        handleTextAreaChange(currentQuestion._id, e.target.value)
                      }
                    />
                  )}

                {currentQuestion.type === "fill-in-the-blank" && (
                  <Form.Control
                    type="text"
                    value={writtenResponses[currentQuestion._id] || ""}
                    onChange={(e) =>
                      handleTextAreaChange(currentQuestion._id, e.target.value)
                    }
                  />
                )}


              </div>
            ) : (
              <Placeholder as="div" animation="glow">
                <Placeholder xs={6} className="mb-2" />
                <Placeholder xs={8} />
                <Placeholder xs={4} />
                <Placeholder xs={7} />
              </Placeholder>
            )}
          </Col>
        </Row>
      </div>
      <div className="d-flex justify-content-end mt-3">
        <Button
          variant="success"
          className="me-2 button-style border-0 px-3 py-2"
          onClick={handleSubmitAnswer}
        >
          Submit Answer
        </Button>
      </div>
      {/* Time up Modal */}
      <Modal show={showTimeUpModal} onHide={() => setShowTimeUpModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Time's Up!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your time for completing this assignment has ended. It has been
          automatically submitted.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => navigate("/assignments")}>
            Go to Assignments
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AttemptExercise;
