import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthVerify, GetUserRoles } from 'utils/auth.utils';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [isVerified, setIsVerified] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userRoles, setUserRoles] = useState([]);

    const navigate = useNavigate();

    const verifyAuth = () => {
        setLoading(true);
        const verify = AuthVerify();
        setIsVerified(verify);
        if (verify) {
            const roles = GetUserRoles();
            setUserRoles(roles);
        } else {
            setUserRoles([]);
        }
        setLoading(false);
    };

    const logout = () => {
        localStorage.removeItem("user");
        setIsVerified(false);
        setUserRoles([]);
        navigate('/login');
    };

    useEffect(() => {
        verifyAuth();
    }, []);

    return (
        <AuthContext.Provider value={{ isVerified, userRoles, verifyAuth, logout, loading, setLoading }}>
            {children}
        </AuthContext.Provider>
    );
};
