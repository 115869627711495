import React from 'react'
import { Route, Routes } from 'react-router-dom'
import TeacherAssignments from './Assignments'
import SubmissionsRoutes from './SubmissionsRoutes'

const TeacherAssignmentRoutes = () => {
    return (
        <>
            <Routes>
                <Route
                    path='/'
                    element={<TeacherAssignments />}
                />
                <Route
                    path='submissions/*'
                    element={<SubmissionsRoutes />}
                />

            </Routes>
        </>
    )
}

export default TeacherAssignmentRoutes