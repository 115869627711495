import { GetApiData } from "utils/http-client";


export const CreateExercise = async (data) => {
    return await GetApiData(`/exercise`, 'POST', data, true);
};
export const getAllSubject = async () => {
    return await GetApiData(`/subject`, 'GET', null, true);
};
export const getExerciseById = async (id) => {
    return await GetApiData(`/exercise/${id}`, 'GET', null, true);
};
export const createExerciseQuestions = async (id, data) => {
    return await GetApiData(`/exercise/question/${id}`, 'POST', data, true);
};
export const deleteExerciseQuestions = async (exercise,id) => {
    return await GetApiData(`/exercise/question/${exercise}?question=${id}`, 'DELETE', null, true);
};
export const updateExercise = async (id,data) => {
    return await GetApiData(`/exercise/${id}`, 'PUT', data, true);
};
export const getAllExercises = async () => {
    return await GetApiData(`/exercise/user`, 'GET', null, true);
};
export const deleteExercises = async (id) => {
    return await GetApiData(`/exercise/${id}`, 'DELETE', null, true);
};
