import React from 'react';
import { Form } from 'react-bootstrap';

const TextArea = ({ heading, rows, value, onChange,placeholder,className,disabled }) => {
    return (
        <>
            <Form.Group className="my-2" controlId="exampleForm.ControlTextarea1">
                {heading && (
                    <Form.Label>{heading}</Form.Label>
                )}

                <Form.Control
                    as="textarea"
                    rows={rows}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    className={className}
                    disabled={disabled}
                />
            </Form.Group>
        </>
    );
};

export default TextArea
