import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import InputComponent from 'components/shared/InputComponent';
import Select from 'components/shared/Select';
import SelectReact from 'react-select';
import { getAllSubject } from 'services/Teacher/Exercise.service';
import { getAllTeachersAndStudents } from 'services/Teacher/User.service';
import { CreateClass } from 'services/Teacher/Class.service';
import { toast } from 'react-toastify';

const AddClassModal = ({ show, handleClose, fetchClasses }) => {
  const [subjects, setSubjects] = useState([]);
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [students, setStudents] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const schoolId = JSON.parse(localStorage.getItem('user'))?.school;

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const response = await getAllTeachersAndStudents(schoolId, 'teacher');
        setTeachers(response.data.users || []);
      } catch (error) {
        console.error('Error fetching teachers:', error);
      }
    };

    const fetchStudents = async () => {
      try {
        const response = await getAllTeachersAndStudents(schoolId, 'student');
        setStudents(response.data.users || []);
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    };

    if (schoolId) {
      fetchTeachers();
      fetchStudents();
    }
  }, [schoolId]);

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await getAllSubject();
        const formattedSubjects = response.data.results.subject.map((subject) => ({
          value: subject._id,
          label: subject.title,
        }));
        setSubjects(formattedSubjects);
      } catch (error) {
        console.error('Error fetching subjects:', error);
      }
    };

    fetchSubjects();
  }, []);

  const handleSubmit = async () => {
    setIsSubmitting(true); // Disable button
    const payload = {
      title: name,
      subject: subject,
      teachers: selectedTeachers.map((teacher) => teacher.value),
      students: selectedStudents.map((student) => student.value),
    };

    const toastId = toast.loading('Creating class...');

    try {
      const response = await CreateClass(payload);
      console.log('Class created successfully:', response);
      handleClose();
      fetchClasses();
      toast.update(toastId, { render: 'Class created successfully!', type: 'success', isLoading: false, autoClose: 3000 });
      
      setName('');
      setSubject('');
      setSelectedTeachers([]);
      setSelectedStudents([]);
    } catch (error) {
      console.error('Error creating class:', error);
      toast.update(toastId, { render: 'Error creating class', type: 'error', isLoading: false, autoClose: 3000 });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header className="border-0" closeButton />
      <Modal.Body>
        <div className="my-2">
          <InputComponent
            label="Class Name"
            placeholder="Enter class name"
            value={name}
            setValue={setName}
          />
        </div>

        <div className="my-2">
          <Select
            heading="Select Subject"
            value={subject}
            onChange={setSubject}
            options={subjects}
            ariaLabel="Select Subject"
            required
          />
        </div>

        <div className="my-2">
          <Form.Label>Select Teachers</Form.Label>
          <SelectReact
            isMulti
            options={teachers.map((teacher) => ({ value: teacher._id, label: teacher.fullName }))}
            value={selectedTeachers}
            onChange={(selected) => setSelectedTeachers(selected)}
            aria-label="Select multiple teachers"
            placeholder="Select teachers"
            className="multi-select"
          />
        </div>

        <div className="my-2">
          <Form.Label>Select Students</Form.Label>
          <SelectReact
            isMulti
            options={students.map((student) => ({ value: student._id, label: student.fullName }))}
            value={selectedStudents}
            onChange={(selected) => setSelectedStudents(selected)}
            aria-label="Select multiple students"
            placeholder="Select students"
            className="multi-select"
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-between pt-0">
        <Button
          variant="secondary"
          className="mt-1 px-4 button-style py-2 border-0"
          style={{ background: 'linear-gradient(270deg, #272727 0%, #4B4B4B 100%)' }}
          onClick={handleClose}
          disabled={isSubmitting}
        >
          Back
        </Button>
        <Button
          variant="primary"
          className="mt-1 px-4 button-style py-2 border-0"
          onClick={handleSubmit}
          disabled={isSubmitting} // Disable during submission
        >
          Yes Create
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddClassModal;





